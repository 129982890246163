import styles from "./ReceiptDetails.module.css";

export default function ReceiptDetails({ receipt }) {
  const handleShareButton = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Revente Receipt",
          text: "Your digital receipt brought to you by Revente",
          url: window.location.href.split("?")[0],
        })
        .then(() => console.log("share successful"))
        .catch((err) => console.log("share failed"));
    } else {
      alert("Oops. Your browser does not support sharing!");
    }
  };

  const convertISOtoSGT = (timeString) => {
    const jsDate = new Date(timeString);
    const receiptDate = jsDate.toLocaleDateString("en-SG");
    const receiptTime = jsDate.toLocaleTimeString("en-SG").toLocaleUpperCase();
    return receiptDate + " - " + " " + receiptTime;
  };

  return (
    <div className={styles.receipt}>
      <div className={styles.receiptInnerContainer}>
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            src={
              receipt.merchant.name.toLowerCase() == "ayam penyet president"
                ? "https://ayampresident.com/wp-content/uploads/2021/06/imageedit_3_7390268724.png"
                : "/placeholderLogo.png"
            }
          />
        </div>
        <hr className={styles.divider} />
        <div className="store-section">
          {/* section item for store name */}
          <div className={styles.sectionItem}>
            <div className={styles.sectionItemLeft}>Store Name</div>
            <div className={styles.sectionItemRight}>
              {receipt.merchant.name}
            </div>
          </div>

          {/* section item for date */}
          <div className={styles.sectionItem}>
            <div className={styles.sectionItemLeft}>Date</div>
            <div className={styles.sectionItemRight}>
              {`${convertISOtoSGT(receipt.receiptTimeStamp)}`}
            </div>
          </div>

          <div className={styles.sectionItem}>
            <div className={styles.companyDetails}>
              {receipt.merchant.registeredName && (
                <div>{receipt.merchant.registeredName}</div>
              )}
              {receipt.branch?.address?.streetAddress},{" "}
              {receipt.branch?.address?.zipCode}
              {receipt.branch?.email && (
                <div>Email: {receipt.branch?.email}</div>
              )}
              {receipt.branch?.phone && (
                <div>Phone: {receipt.branch?.phone}</div>
              )}
              {receipt.merchant.gstRegistrationNumber && (
                <div>GST Reg. No. {receipt.merchant.gstRegistrationNumber}</div>
              )}
            </div>
          </div>
        </div>
        {/* custom header section */}
        {(receipt.customHeaderText || receipt.receiptNumber) && (
          <hr className={styles.customHeader} />
        )}
        {(receipt.customHeaderText || receipt.receiptNumber) && (
          <div className={styles.headerSection}>
            <p className={styles.customHeader}>{receipt.customHeaderText}</p>
            <p className={styles.sectionItemLeft}>
              Receipt Number:{" "}
              <p className={styles.receiptNumber}>{receipt.receiptNumber}</p>
            </p>
          </div>
        )}

        <hr className={styles.divider} />
        <div className="items-section">
          {receipt.products &&
            receipt.products.map((item, index) => {
              return (
                <div key={index} className={styles.skuItem}>
                  <div className={styles.productSectionItem}>
                    <div className={styles.sectionItemLeft}>
                      {item.name}
                      <div className={styles.itemFreeText}>
                        {item.freeText && <div>{item.freeText}</div>}
                        {item.quantity > 1 && (
                          <div>
                            {item.quantity > 1
                              ? ` (${item.quantity} x $${item.unitPrice.toFixed(
                                  2
                                )}/unit)`
                              : ""}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.sectionItemRight}>
                      ${item.totalPrice.toFixed(2)}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <hr className={styles.divider} />
        <div className="total-section">
          <div className={styles.sectionItem}>
            <div className={styles.sectionItemLeft}>Sub Total</div>
            <div className={styles.sectionItemRight}>
              ${receipt.subTotal.toFixed(2)}
            </div>
          </div>
          {receipt.totalDiscount > 0 && (
            <div className={styles.sectionItem}>
              <div className={styles.sectionItemLeft}>Discount</div>
              <div className={styles.sectionItemRight}>
                - ${receipt.totalDiscount.toFixed(2)}
              </div>
            </div>
          )}
          {receipt.totalDiscount > 0 && (
            <div className={styles.sectionItem}>
              <div className={styles.sectionItemLeft}>
                Sub Total After Discount
              </div>
              <div className={styles.sectionItemRight}>
                ${receipt.subTotalDiscount.toFixed(2)}
              </div>
            </div>
          )}
          {receipt.totalServiceChargeAmount > 0 && (
            <div className={styles.sectionItem}>
              <div className={styles.sectionItemLeft}>
                Service Charge{" "}
                {receipt.merchant.serviceChargeRate.toLocaleString("en", {
                  style: "percent",
                })}
              </div>
              <div className={styles.sectionItemRight}>
                ${receipt.totalServiceChargeAmount.toFixed(2)}
              </div>
            </div>
          )}
          <div className={styles.sectionItem}>
            <div className={styles.sectionItemLeft}>
              GST{" "}
              {receipt.merchant.gstRate.toLocaleString("en", {
                style: "percent",
              })}
            </div>
            <div className={styles.sectionItemRight}>
              ${receipt.totalGSTAmount.toFixed(2)}
            </div>
          </div>
          <div className={styles.sectionItem}>
            <div className={styles.sectionItemLeft}>
              {"Total (Inclusive of GST)"}
            </div>
            <div className={styles.totalAmount}>
              ${receipt.totalPrice.toFixed(2)}
            </div>
          </div>
        </div>
        <hr className={styles.divider} />
        {/* payment section */}
        <div>
          <div className={styles.sectionItem}>
            <div className={styles.itemFreeText}>
              {receipt.payment.type && (
                <div>Paid by {receipt.payment.type.toLowerCase()}</div>
              )}
              {receipt.payment.timeStamp && (
                <div>Paid at {convertISOtoSGT(receipt.payment.timeStamp)}</div>
              )}
            </div>
          </div>
        </div>
        <hr className={styles.divider} />
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.buttonContainer}>
          <button
            className={styles.downloadButton}
            onClick={() => {
              if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.print();
              } else {
                document.execCommand("print", false, null);
              }
            }}
          >
            Download
          </button>
          <button onClick={handleShareButton} className={styles.shareButton}>
            Share
          </button>
        </div>
      </div>
    </div>
  );
}
