import React, { forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function CustomizedSnackbars({
  open,
  handleClose,
  type,
  message,
}) {
  const renderAlert = () => {
    if (type === "success") {
      return (
        <Alert onClose={handleClose} severity='success' sx={{ width: "100%" }}>
          {message}
        </Alert>
      );
    } else if (type === "error") {
      return (
        <Alert onClose={handleClose} severity='error' sx={{ width: "100%" }}>
          {message}
        </Alert>
      );
    }
    return null;
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      {renderAlert()}
    </Snackbar>
  );
}
