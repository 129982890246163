import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { httpsCallable } from "firebase/functions";
import { doc, setDoc } from "firebase/firestore";
import { db, functions } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import CustomizedSnackbars from "../components/CustomizedSnackbars";

function Signup() {
  const { signup } = useAuth();
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, mobile, password, confirmPassword } =
      e.target.elements;

    if (password.value !== confirmPassword.value) {
      setOpen(true);
      setMessageType("error");
      setMessage("Passwords do not match");
      return;
    }

    try {
      setMessage("");
      setLoading(true);

      const user = await createUserInAuth(email.value, password.value);
      const userRef = await createUserInFirestore(
        user,
        name.value,
        email.value,
        mobile.value
      );
      await setupSpendingRecord(userRef);

      setOpen(true);
      setMessage("Account created successfully!");
      setMessageType("success");

      navigate("/");
    } catch (error) {
      console.log(error);
      setOpen(true);
      setMessageType("error");
      setMessage("Failed to create an account");
    } finally {
      setLoading(false);
    }
  };

  const createUserInAuth = async (email, password) => {
    try {
      const userCredential = await signup(email, password);
      return userCredential.user;
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        throw new Error("That email address is already in use!");
      }
      if (error.code === "auth/invalid-email") {
        throw new Error("That email address is invalid!");
      }
      throw error;
    }
  };

  const createUserInFirestore = async (user, name, email, mobile) => {
    const userRef = doc(db, "users", user.uid);
    await setDoc(
      userRef,
      {
        name,
        mobile,
        email,
        receipts: [],
      },
      { merge: true }
    );
    return userRef;
  };

  const setupSpendingRecord = async (userRef) => {
    await httpsCallable(functions, "createEmptySpendingRecordForUser")();
    return userRef;
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign up
        </Typography>
        <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete='given-name'
                name='name'
                required
                fullWidth
                id='name'
                label='Name'
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id='mobile'
                label='Mobile Number'
                name='mobile'
                autoComplete='tel'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='new-password'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name='confirmPassword'
                label='Confirm Password'
                type='password'
                id='confirmPassword'
                autoComplete='new-password'
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {loading ? "Signing Up..." : "Sign Up"}
          </Button>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <Link href='Singin' variant='body2'>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CustomizedSnackbars
        open={open}
        handleClose={handleClose}
        type={messageType}
        message={message}
      />
    </Container>
  );
}

export default Signup;
