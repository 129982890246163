import React, { useState } from "react";
import { Card, CardContent, Typography, Button, Alert } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";

export default function Profile() {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      navigate("/signin");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant='h2' component='div' align='center' gutterBottom>
            Profile
          </Typography>
          {error && <Alert severity='error'>{error}</Alert>}
          <Typography
            variant='body1'
            component='div'
            align='center'
            gutterBottom
          >
            <strong>Email:</strong> {currentUser.email}
          </Typography>
          <Button
            component={Link}
            to='/update-profile'
            variant='contained'
            color='primary'
            fullWidth
            sx={{ mt: 3 }}
          >
            Update Profile
          </Button>
        </CardContent>
      </Card>
      <div style={{ width: "100%", textAlign: "center", marginTop: "2rem" }}>
        <Button variant='text' onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </>
  );
}
