import React from "react";
import {
  Typography,
  Box,
  Container,
  Avatar,
  Link,
  Button,
} from "@mui/material";
import Footer from "../components/Footer";
import { useParams, useSearchParams } from "react-router-dom";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useState, useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import ReceiptDetails from "../components/ReceiptDetails";
import styles from "./Receipt.module.css";
import Spinner from "../components/Spinner";

export default function Receipt() {
  const { receiptid } = useParams();
  const [receipt, setReceipt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [receiptSource, setReceiptSource] = useState(null);

  useEffect(() => {
    if (searchParams.get("type")) {
      setReceiptSource(searchParams.get("type"));
    }
  }, [searchParams])


  useEffect(() => {
   const updateTracking = async () => { 
    if (receiptSource == "nfc") {
      const docRef = doc(db, "tracking", "nfc");
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const data = await docSnapshot.data();
        data.receipts.push({receiptId: receiptid, timeStamp: new Date().toISOString()});
        updateDoc(docRef, data);
      }
    } else if (receiptSource == "qr") {
      const docRef = doc(db, "tracking", "qr");
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const data = await docSnapshot.data();
        data.receipts.push({receiptId: receiptid, timeStamp: new Date().toISOString()});
        updateDoc(docRef, data);
      }
    }
   }
   updateTracking();
  }, [receiptSource]);


  const fetchReceipt = async (documentName) => {
    const docRef = doc(db, "receipts", documentName);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) return await docSnapshot.data();
    return null;
  };

  const fetchBranch = async (documentName) => {
    const docRef = doc(db, "branches", documentName);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) return await docSnapshot.data();
    return null;
  };

  const fetchMerchant = async (documentName) => {
    const docRef = doc(db, "merchants", documentName);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) return await docSnapshot.data();
    return null;
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const receiptData = await fetchReceipt(receiptid);
      if (receiptData) {
        const branchData = await fetchBranch(receiptData.branchId);
        if (branchData) {
          const merchantData = await fetchMerchant(branchData.merchantId);
          if (merchantData) {
            setReceipt({
              ...receiptData,
              merchant: merchantData,
              branch: branchData,
            });
          }
        }
      }
      setLoading(false);
    }
    fetchData();
  }, [receiptid]);

  return (
    <div className={styles.app}>
      <div className={styles.content}>
        {/* page header */}
        <div className={styles.header}>
          <img className={styles.icon} src="/revent_black_r.png" />
          <h4 className={styles.title}>Digital Receipt</h4>
        </div>
        {/* main receipt section */}
        {loading && (
          <div style={{ textAlign: "center" }}>
            <h1>Loading</h1>
            <p>Please wait while we get your receipt...</p>
            <Spinner />
          </div>
        )}
        {receipt && <ReceiptDetails receipt={receipt} />}
        {!receipt && !loading && (
          <div style={{ textAlign: "center" }}>
            The receipt either does not exist or has expired
          </div>
        )}
      </div>
      <section className={styles.footerSection}>
        <div>
          Digital receipts brought to you by Revente. <br></br>Visit{" "}
          <a target="_blank" href="https://www.revente.co">revente.co</a> to learn more!
        </div>
        <div>Interested in collaborations? Let's connect</div>
        <div className={styles.socialsContainer}>
          <Link
            href="mailto:business@revente.co"
            className={styles.iconContainer}
          >
            <EmailIcon />
          </Link>
          {/* <Link
            onClick={() => alert("Coming soon! Please email us for now.")}
            className={styles.iconContainer}
          >
            <FacebookIcon />
          </Link> */}
          <Link
            target="_blank"
            href="https://www.instagram.com/revente.co/"
            className={styles.iconContainer}
          >
            <InstagramIcon />
          </Link>
          <Link
            target="_blank"
            href="https://www.linkedin.com/company/revente-co/about"
            className={styles.iconContainer}
          >
            <LinkedInIcon />
          </Link>
        </div>
        <p>
          {"Copyright © "}
          <a color="inherit" href="https://www.revente.co">
            Revente Pte. Ltd.
          </a>{" "}
          {new Date().getFullYear()}
          {"."}
        </p>
      </section>
    </div>
  );
}
